import garantiasAdminApi from "../../garantias_admin.api";
import utils from "@/helpers/utilidades";

export default {

  campos_dinamicos(){
    return garantiasAdminApi.api.get(`${garantiasAdminApi.baseURL}/personas/creditos/campos`);
  }

  ,index(persona, options = {}, paginado = false) {
    return garantiasAdminApi.api.get(utils.url_options(`${garantiasAdminApi.baseURL}/personas/${persona}/creditos`, options, paginado));
  }

  ,obtener(persona, credito) {
    return garantiasAdminApi.api.get(`${garantiasAdminApi.baseURL}/personas/${persona}/creditos/${credito}`);
  },

  crear(persona, credito, payload) {
    return garantiasAdminApi.api.post(`${garantiasAdminApi.baseURL}/personas/${persona}/creditos`, payload)
  },

  editar(persona,credito, payload) {
    return garantiasAdminApi.api.put(`${garantiasAdminApi.baseURL}/personas/${persona}/creditos/${credito}`, payload)
  },

  eliminar(persona,credito) {
    return garantiasAdminApi.api.delete(`${garantiasAdminApi.baseURL}/personas/${persona}/creditos/${credito}`)
  },

  reestructurar(persona,credito, payload) {
    return garantiasAdminApi.api.post(`${garantiasAdminApi.baseURL}/personas/${persona}/creditos/${credito}/reestructurar`, payload)
  },

}