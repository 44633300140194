
<template>

  <div>

    <Modal  :options="{width: '40vw'}">
      <div class="title h2 text-white">
        Alta clientes
      </div>

      <div class="body">
        <div class="text-center my-4">
          <div class="h4">Alta clientes</div>
          <h5 class="text-secondary">(Paso {{paso}}/{{ultimo_paso}})</h5>
        </div>

        <div class="container-fluid">
          <div class="row" v-show="paso === 1">
            <div class="col-sm-12 form-group">
              <div class="mb-4">
                <div class="h5">Selecciona excel de carga </div>
              </div>
              <input type="file" name="archivo_clientes" id="archivo_clientes" class="form-control" accept=".xlsx" @change="cargarArchivo($event)">
            </div>
          </div>

          <div v-if="paso === 2">
            <div class="mb-4">
              <div class="h5">Campos Cliente </div>
              <span class="text-secondary">Relacione los valores de las columnas del archivo para las propiedades del cliente.</span>
            </div>

            <div class="form-group row" v-for="(campo_persona,index_persona) of campos_alta_persona">
              <label for="staticEmail" class="col-sm-6 col-form-label">{{index_persona+1}}.  {{ campo_persona.nombre }}</label>
              <div class="col-sm-6">
                <select class="form-control" :name="!campo_persona.id ? campo_persona.clave : campo_persona.id" :id="!campo_persona.id ? campo_persona.clave : campo_persona.id"  v-model="campo_persona.columna_excel" >
                  <option :value="null">Ninguna</option>
                  <option  v-for="columna_persona in colummnas_archivo" :value="columna_persona">{{columna_persona}}</option>
                </select>
              </div>
            </div>
          </div>

          <div v-if="paso === 3">
            <div class="mb-4">
              <div class="h5">Campos Crédito </div>
              <span class="text-secondary">Relacione los valores de las columnas del archivo para las propiedades del crédito.</span>
            </div>

            <div class="form-group row" v-for="(campo_credito,index_credito) of campos_alta_credito">
              <label for="staticEmail" class="col-sm-6 col-form-label">{{index_credito+1}}.  {{ campo_credito.nombre }}</label>
              <div class="col-sm-6">
                <select class="form-control" :name="!campo_credito.id ? campo_credito.clave : campo_credito.id" :id="!campo_credito.id ? campo_credito.clave : campo_credito.id"  v-model="campo_credito.columna_excel" >
                  <option :value="null">Ninguna</option>
                  <option  v-for="columna_credito in colummnas_archivo" :value="columna_credito">{{columna_credito}}</option>
                </select>
              </div>
            </div>
          </div>

          <div v-if="paso === 4">
            <div class="mb-4">
              <div class="h5">Campos Garantía </div>
              <span class="text-secondary">Relacione los valores de las columnas del archivo para las propiedades de la garantía.</span>
            </div>

            <div class="form-group row" v-for="(campo_garantia,index_garantia) of campos_alta_garantia">
              <label for="staticEmail" class="col-sm-6 col-form-label">{{index_garantia+1}}.  {{ campo_garantia.nombre }}</label>
              <div class="col-sm-6">
                <select class="form-control" :name="!campo_garantia.id ? campo_garantia.clave : campo_garantia.id" :id="!campo_garantia.id ? campo_garantia.clave : campo_garantia.id"  v-model="campo_garantia.columna_excel" >
                  <option :value="null">Ninguna</option>
                  <option  v-for="columna_garantia in colummnas_archivo" :value="columna_garantia">{{columna_garantia}}</option>
                </select>
              </div>
            </div>
          </div>

          <div v-if="paso === 5">
            <div class="mb-4">
              <div class="h5">Campos Fideicomiso </div>
              <span class="text-secondary">Relacione los valores de las columnas del archivo para las propiedades del fideicomiso.</span>
            </div>

            <div class="form-group row" v-for="(campo_fideicomiso,index_fideicomiso) of campos_alta_fideicomiso">
              <label for="staticEmail" class="col-sm-6 col-form-label">{{index_fideicomiso+1}}.  {{ campo_fideicomiso.nombre }}</label>
              <div class="col-sm-6">
                <select class="form-control" :name="!campo_fideicomiso.id ? campo_fideicomiso.clave : campo_fideicomiso.id" :id="!campo_fideicomiso.id ? campo_fideicomiso.clave : campo_fideicomiso.id"  v-model="campo_fideicomiso.columna_excel" >
                  <option :value="null">Ninguna</option>
                  <option  v-for="columna_fideicomiso in colummnas_archivo" :value="columna_fideicomiso">{{columna_fideicomiso}}</option>
                </select>
              </div>
            </div>
          </div>

          <div v-if="paso === ultimo_paso">
            <div class="mb-4">
              <div class="h5">Confirmar carga de clientes.</div>
            </div>

            <div class="alert alert-info" role="alert">
              <i class="fa fa-info-circle mr-sm-1"></i>
              <b> Total de registros a procesar</b>: {{this.request_clientes.length}} <br>
              Para confirmar el total de carga de clientes y los valores relacionados en el paso anterior de clic el botón de "cargar clientes" en la parte inferior.
            </div>
          </div>



        </div>


      </div>


      <div class="footer mb-4">
        <div class="row justify-content-end">
          <div class="col-auto" v-if="paso > 1"><button type="button" class="btn btn-secondary" @click="paso--" id="btn-crear" name="btn-crear"><i class='fa fa-arrow-left mr-sm-1'></i>Regresar</button></div>
          <div class="col-auto">
            <button type="button" class="btn btn-success" @click="avanzar_pasos" id="btn-crear" name="btn-crear">
              <i class='fa fa-arrow-right mr-sm-1'></i>{{ paso < ultimo_paso ? 'Continuar' : 'Cargar clientes' }}
            </button>
          </div>
          <div class="col-auto"><button class="btn btn-danger" @click="$emit('cancelar')"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>


    </Modal>


  </div>


</template>

<script>
import personasApi from "@/apps/garantias_app/api/personas/personas.api";
import creditosApi from "@/apps/garantias_app/api/personas/creditos/creditos.api";
import fideicomisosApi from "@/apps/garantias_app/api/personas/fideicomisos/fideicomisos.api";
import Modal from "@/components/Modal.vue";
import garantiasApi from "@/apps/garantias_app/api/personas/creditos/garantias.api";

export default {
  name: "FormularioAltaMasivaClientes"
  ,props:{}
  ,components: {Modal}
  ,data(){
    return {
      campos_dinamicos_persona: []
      ,campos_dinamicos_credito: []
      ,campos_dinamicos_garantia: []
      ,campos_dinamicos_fideicomiso: []
      ,colummnas_archivo: []
      ,data_archivo: []
      ,request_clientes: []
      ,campos_alta_persona_default: [
        {nombre: 'Nombre',                clave: 'nombre',                columna_excel: null },
        {nombre: 'Apellido Paterno',      clave: 'apellido_paterno',      columna_excel: null },
        {nombre: 'Apellido Materno',      clave: 'apellido_materno',      columna_excel: null },
        {nombre: 'Correo',                clave: 'correo',                columna_excel: null },
        {nombre: 'Persona Juridica',      clave: 'persona_juridica',      columna_excel: null },
        {nombre: 'Identificador Externo', clave: 'identificador_externo', columna_excel: null },
      ]
      ,campos_alta_credito_default: [
        {nombre: 'No. Crédito', clave: 'no_credito',    columna_excel: null },
        {nombre: 'Estatus',     clave: 'estatus',       columna_excel: null },
        {nombre: 'Fondeador',   clave: 'fondeador',     columna_excel: null },
      ]
      ,campos_alta_garantia_default: [
        {nombre: 'Identificador externo/folio ', clave: 'identificador',    columna_excel: null },
        {nombre: 'Estatus',                      clave: 'estatus',          columna_excel: null },
        {nombre: 'Tipo de inmueble',             clave: 'tipo_inmueble',    columna_excel: null },
        {nombre: 'Pais',                         clave: 'pais',           columna_excel: null },
        {nombre: 'Estado',                       clave: 'estado',           columna_excel: null },
        {nombre: 'Alcaldía/Municipio',           clave: 'municipio',        columna_excel: null },
        {nombre: 'C.P.',                         clave: 'cp',               columna_excel: null },
        {nombre: 'Calle',                        clave: 'calle',            columna_excel: null },
        {nombre: 'Número exterior',              clave: 'numero_exterior',  columna_excel: null },
        {nombre: 'Número interior',              clave: 'numero_interior',  columna_excel: null },
        {nombre: 'Colonia',                      clave: 'colonia',          columna_excel: null },
        {nombre: 'Ubicación Inmueble',           clave: 'ubicacion',        columna_excel: null },

          //Tuipo de garantia juridica es de la relacion entre garantia y fideicomiso
        {nombre: 'Tipo garantía juridica',       clave: 'tipo_garantia_juridica',        columna_excel: null },
      ],
      campos_alta_fideicomiso_default: [
        {nombre: 'No. del fideicomiso ',                      clave: 'no_fideicomiso',            columna_excel: null },
        {nombre: 'Fideicomisarios/Beneficiarios',             clave: 'beneficiarios',             columna_excel: null },// Si hay mas de un beneficiarios se separa cada uno por '|'
        {nombre: 'Fideicomisarios/Beneficiarios Tipos',       clave: 'beneficiarios_tipos',       columna_excel: null }, //El orden de los tipos debe coinicidir con el input de beneficiarios, cada uno separado por un '|'
        {nombre: 'Fideicomisarios/Beneficiarios Porcentajes', clave: 'beneficiarios_porcentajes', columna_excel: null }, //El orden de los tipos debe coinicidir con el input de beneficiarios, cada uno separado por un '|'
        {nombre: 'Estatus',                                   clave: 'estatus',                   columna_excel: null },
      ],

       campos_alta_persona: []
      ,campos_alta_credito: []
      ,campos_alta_garantia: []
      ,campos_alta_fideicomiso: []
      ,paso: 1
      ,ultimo_paso: 6
      ,archivo: null
    }
  }
  ,mounted() {
    this.campos_alta_persona     = this.campos_alta_persona_default
    this.campos_alta_credito     = this.campos_alta_credito_default
    this.campos_alta_garantia    = this.campos_alta_garantia_default
    this.campos_alta_fideicomiso = this.campos_alta_fideicomiso_default

    this.cargarCamposDinamicos()
  }
  ,computed:{
  }
  ,methods:{

    async cargarArchivo(event){
      this.archivo = event.target.files[0]
    },

    async leerArchivo(){

      let formData = new FormData();
      formData.append('archivo', this.archivo);

      const response = await personasApi.alta_masiva_leer_archivo(formData)

      this.colummnas_archivo = response.data.columnas;
      this.data_archivo = response.data.data;
      this.paso = 2
    },



    async cargarCamposDinamicos(){

      //Cargar campos dinámicos para crear persona

      this.campos_dinamicos_persona = []
      let dataSourceCamposPersona = await personasApi.campos_dinamicos()

      let campos_dinamicos_persona  = this.proesar_campos_dinamicos(dataSourceCamposPersona.data, this.campos_alta_persona_default)
      this.campos_dinamicos_persona = campos_dinamicos_persona.campos_dinamicos
      this.campos_alta_persona      = campos_dinamicos_persona.campos_alta


      // Cargar campos dinámicos crédito

      this.campos_dinamicos_credito = []
      let dataSourceCamposCredito = await creditosApi.campos_dinamicos()

      let campos_dinamicos_credito  = this.proesar_campos_dinamicos(dataSourceCamposCredito.data, this.campos_alta_credito_default)
      this.campos_dinamicos_credito = campos_dinamicos_credito.campos_dinamicos
      this.campos_alta_credito      = campos_dinamicos_credito.campos_alta

      // Cargar campos dinámicos garantía

      this.campos_dinamicos_garantia = []
      let dataSourceCamposGarantia = await garantiasApi.campos_dinamicos()

      let campos_dinamicos_garantia  = this.proesar_campos_dinamicos(dataSourceCamposGarantia.data, this.campos_alta_garantia_default)
      this.campos_dinamicos_garantia = campos_dinamicos_garantia.campos_dinamicos
      this.campos_alta_garantia      = campos_dinamicos_garantia.campos_alta


      // Cargar campos dinámicos fideicomiso

      this.campos_dinamicos_fideicomiso = []

      let dataSourceCamposFideicomiso = await fideicomisosApi.campos_dinamicos()

      let campos_dinamicos_fideicomiso  = this.proesar_campos_dinamicos(dataSourceCamposFideicomiso.data, this.campos_alta_fideicomiso_default)
      this.campos_dinamicos_fideicomiso = campos_dinamicos_fideicomiso.campos_dinamicos
      this.campos_alta_fideicomiso      = campos_dinamicos_fideicomiso.campos_alta

    }

    ,proesar_campos_dinamicos(campos_dinamicos, campos_alta = []){

      let campos = { campos_alta: campos_alta, campos_dinamicos: campos_dinamicos }

      for (const campoKey in campos_dinamicos) {
        const campo = campos_dinamicos[campoKey]
        /**  se descartan los campos dinámicos que no esten activos para los nuevos registros */
        if (!campo.activo) {
          campos.campos_dinamicos.splice(campoKey,1)
        }else {
          campos.campos_alta.push({...campo, columna_excel: null})
        }
      }

      return campos;
    }

    ,avanzar_pasos(){

      switch (this.paso) {
        case 1:
          this.leerArchivo()
          break
        case 2:
         this.paso = 3
          break
        case 3:
          this.paso = 4
          break
        case 4:
          this.paso = 5
          break
        case 5:
          this.pre_cargar_clientes()
          break
        case 6:
          this.cargar_clientes()
          break
      }

    }

    ,pre_cargar_clientes(){
      const spinner = document.querySelectorAll('.loading').length > 0;

      if(spinner) {
        document.querySelectorAll('.loading')[0].style.display = 'block';
      }

      this.request_clientes = [];

      for (const cliente_archivo of this.data_archivo) {

        let payload_cliente = { tipo: 'cliente', credito: {}, garantia: {}, fideicomiso: {} }

        if (cliente_archivo.id) {
          payload_cliente['id'] = cliente_archivo.id
        }

        //Valores payload para persona
        for (const campo_persona of this.campos_alta_persona) {
          payload_cliente[campo_persona.clave] = campo_persona.columna_excel ?  cliente_archivo[campo_persona.columna_excel] : null
        }

        //Payload de crédito
        for (const campo_credito of this.campos_alta_credito) {
          payload_cliente.credito[campo_credito.clave] = campo_credito.columna_excel ?  cliente_archivo[campo_credito.columna_excel] : null
        }

        //Payload de garantía
        for (const campo_garantia of this.campos_alta_garantia) {
          payload_cliente.garantia[campo_garantia.clave] = campo_garantia.columna_excel ?  cliente_archivo[campo_garantia.columna_excel] : null
        }

        //Payload de fideicomiso
        for (const campo_fideicomiso of this.campos_alta_fideicomiso) {
          payload_cliente.fideicomiso[campo_fideicomiso.clave] = campo_fideicomiso.columna_excel ?  cliente_archivo[campo_fideicomiso.columna_excel] : null
        }

        this.request_clientes.push(payload_cliente)
      }

      if(spinner) {
        document.querySelectorAll('.loading')[0].style.display = 'none';
      }

      this.paso = this.ultimo_paso

    }

    ,async cargar_clientes(){
        try {
          const request = await personasApi.alta_masiva({'clientes': this.request_clientes})

          const mensaje = !request.data.message ? 'Información procesada correctamente.' : request.data.message;

          this.$helper.showMessage('Cargar clientes', mensaje, 'success', 'alert')

          this.$emit('cancelar') //cerrar formulario

        } catch (e) {
          this.$log.error('Error', e);
          if (e.response && e.response.status !== 400)
            this.$helper.showMessage( 'Cargar clientes', 'Ocurrio un error al cargar los clientes, favor de reportarlo al administador.', 'error', 'alert')
        }
      }


  }
}
</script>

<style scoped lang="scss">

</style>
